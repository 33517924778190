export default function privateFilterEvent(exception, filterType) {
  const filterValues = {
    connectionFailureError: /before the deadline|has been closed/,
    unnecessaryError:
      /Unauthorized|BadRequest|NotFound|ChunkLoadError|SecurityError/,
    unhandledRejection: /UnhandledRejection/,
    qismoError: /qismo/i,
  }

  const result = exception.values.reduce((isTrue, eachException) => {
    if (
      isTrue ||
      (eachException.value &&
        eachException.value.match(filterValues[filterType])) ||
      (eachException.type && eachException.type.match(filterValues[filterType]))
    ) {
      return true
    }

    return isTrue
  }, false)

  return result
}
