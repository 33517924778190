const navbarInvertColorUrls = [
  /\/auth\//,
  /\/app\//,
  /\/faq/,
  /\/careers/,
  /\/privacy-policy/,
  /\/contact-us/,
  /\/term-of-use/,
  /\/info/,
  /\/blog/,
  /\/quality/,
  /\/gift-card/,
  /\/products/,
  /\/promotion/,
  /\/clean-standard/,
]

export default navbarInvertColorUrls
