const HttpBackend = require('i18next-http-backend/cjs')
const ChainedBackend = require('i18next-chained-backend').default
const LocalStorageBackend = require('i18next-localstorage-backend').default

module.exports = {
  backend: {
    backendOptions: [
      { expirationTime: 60 * 60 * 1000 }, // 1 hour
      { loadPath: '/locales/{{ns}}/{{lng}}.json' },
    ],
    backends: typeof window !== 'undefined'
      ? [LocalStorageBackend, HttpBackend]
      : []
  },
  serializeConfig: false,
  use: typeof window !== 'undefined'
    ? [ChainedBackend]
    : [],
  i18n: {
    defaultLocale: 'id',
    fallbackLng: 'id',
    locales: ['id','en'],
    localeDetection: false,
    localePath: './public/locales',
    localeStructure: '{{ns}}/{{lng}}',
    ns: [
      'bff',
      'campaign',
      'common',
      'completingSignUp',
      'digitalMerchandise',
      'faq',
      'giftCard',
      'giftCardLandingPage',
      'info',
      'landing',
      'products',
      'promoLanding',
      'quiz',
      'recycle',
      'reseller',
      'signin',
      'signup',
      'backToBaseic',
      'sustainability',
      'productFaq',
      'auth',
    ],
    defaultNS: 'common',
    interpolation: { escapeValue: false },
    load: 'languageOnly',
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
    },
    reloadOnPrerender: process.env.ENVIRONMENT === 'local',
  },
}