import { useEffect, useRef } from 'react'

/**
 * ### useDidMount
 *
 * a custom hook to properly mimic componentDidMount behaviour
 * on React Class Components, because on react 18 strict mode,
 * useEffect run twice even with empty dependency array.
 *
 * if there is a cleanup function,
 * return a cleanup function inside effectFn param
 *
 * Note:
 *  - The second parameter is a boolean to indicate that you are using
 * useLayoutEffect function instead of useEffect
 *  - Use this if the effect dependency array is empty
 *
 * ```
 * // Example
 * useDidMount(() => { // <-- normal effect function
 *   setMounted(true)
 *
 *   return () => { // <-- return cleanup function
 *     setMounted(false)
 *   }
 * })
 * ```
 *
 * @param {Function} effectFn effect function to run
 * @returns {void}
 */
function useDidMount(effectFn) {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current === false && typeof effectFn === 'function') {
      didMountRef.current = true

      const cleanupFn = effectFn()

      return () => cleanupFn
    }
  }, [effectFn])
}

export default useDidMount
