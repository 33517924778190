import { PureComponent } from 'react'
import * as Sentry from '@sentry/core'

import FlatButton from '../FlatButton'
import Text from '../Text'

import styles from './style.module.css'

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    this.tryReload = this.tryReload.bind(this)
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, {
      contexts: {
        errorBoundary: {
          errorInfo,
          errorBoundary: true,
        },
      },
    })
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  tryReload() {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <Text tag="span" variant="subtitle">
            Sorry, something went wrong,
          </Text>
          &nbsp;
          <Text tag="span" variant="subtitle" className={styles.clickable}>
            <FlatButton onClick={this.tryReload}>try again?</FlatButton>
          </Text>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
