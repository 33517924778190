import { useState, useEffect, useCallback } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Trans, useTranslation } from 'next-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import Link from 'next/link'

import SnackBar from '../SnackBar'
import Text from '../Text'
import styles from './style.module.css'

import { getCart } from '@gql/cartQuery'
import { getCurrentUserForNavbar, getUserNotification } from '@gql/userQuery'
import { getAvailableDiscounts } from '@gql/discountQuery'
import {
  removeItemsFromCartMutation,
  removeBundlesFromCartMutation,
} from '@gql/cartMutation'
import { useDidMount, useIsWidthLarger } from '@utils/hooks'
import { calculateDiscount } from '@utils/helpers'
import { links, accountLinks, mobileLinks } from '@constants/navbarLinks'
import navbarInvertColorUrls from '@constants/navbarInvertColorUrls'
import { unsetUser } from '@utils/sentry'
import { useTracker } from '@utils/trackerContext'
import useAuth from '@utils/auth/client'
import orderConfig from '@config/order'
import { useLocal } from '@utils/localContext'

const orderType = orderConfig.isPreorder ? 'Preorder' : 'Normal'

// Dynamic loaded component

const NavbarDesktop = dynamic({
  ssr: false,
  loader: () =>
    import(/* webpackChunkName: "navbar-desktop" */ './NavbarDesktop'),
})
const NavbarMobile = dynamic({
  ssr: false,
  loader: () =>
    import(/* webpackChunkName: "navbar-mobile" */ './NavbarMobile'),
})

function Navbar() {
  const isLarge = useIsWidthLarger(1024)
  const router = useRouter()
  const tracker = useTracker()
  const { t, i18n, ready } = useTranslation('common')
  const {
    authState: { user: authUser },
    logout,
  } = useAuth()
  const local = useLocal()
  const userData = local.getQiscusWidgetUser()

  const [mounted, setMounted] = useState(false)
  const [activeSidebar, setActiveSidebar] = useState(false)
  const [isRemovingItems, setIsRemovingItems] = useState(false)
  const [isSnackbarHidden, setIsSnackbarHidden] = useState(false)

  // Apollo
  const [asyncGetCurrentUser, { data: { currentUser } = {} }] = useLazyQuery(
    getCurrentUserForNavbar
  )

  const [asyncGetCart, { data: { cart } = {} }] = useLazyQuery(getCart, {
    variables: { orderType },
  })
  const [asyncGetDiscounts, { data: { availableDiscounts = [] } = {} }] =
    useLazyQuery(getAvailableDiscounts, {
      variables: { userId: userData && userData.user_id },
    })
  const [asyncGetUserNotification, { data: { notifications = [] } = {} }] =
    useLazyQuery(getUserNotification)
  const [removeItemsFromCart] = useMutation(removeItemsFromCartMutation)
  const [removeBundlesFromCart] = useMutation(removeBundlesFromCartMutation)

  // Handlers

  const internalLogout = useCallback(async () => {
    unsetUser()
    tracker.unsetUserData()
    logout()
  }, [tracker, logout])

  const removeItemsFromCartHandler = useCallback(
    async item => {
      try {
        setIsRemovingItems(true)

        switch (item.type) {
          case 'product': {
            await removeItemsFromCart({
              variables: {
                items: [{ productId: item.id, quantity: item.quantity }],
                orderType,
              },
            })
            break
          }
          case 'bundle': {
            await removeBundlesFromCart({
              variables: {
                bundles: [
                  { bundleId: parseInt(item.id), quantity: item.quantity },
                ],
              },
            })
            break
          }
        }
      } finally {
        setIsRemovingItems(false)
      }
    },
    [removeItemsFromCart, removeBundlesFromCart]
  )

  const toggleSidebar = useCallback(() => {
    setActiveSidebar(prevState => !prevState)
  }, [])

  const changeLanguage = useCallback(
    lang => {
      /** ----- Legacy ----- */
      // setLang(lang)
      // i18n.changeLanguage(lang)
      /** ----- Legacy ----- */

      // use next internationalized route
      router.replace(
        {
          pathname: router.pathname,
          query: router.query,
        },
        undefined,
        {
          locale: lang,
        }
      )
    },
    [router]
  )

  const renderSnackbar = useCallback(() => {
    if (!ready && !mounted) return null

    // check current user has first voucher
    const snackbarVoucher = currentUser?.firstVoucher
    const discountValue = snackbarVoucher?.discount
      ? snackbarVoucher.discount.percentage
        ? `${snackbarVoucher.discount.percentage} %`
        : `Rp. ${snackbarVoucher.discount.amount}`
      : ''

    const utm = local.getUtmData()
    const isPaidAdsOrCpc =
      utm.utmMedium === 'paid-ads' ||
      utm.utmMedium === 'cpc' ||
      router.query?.utm_medium === 'paid-ads' ||
      router.query?.utm_medium === 'cpc'

    if (isPaidAdsOrCpc && !currentUser?.hasCompletedOrder) {
      return (
        <SnackBar className={styles.snackbar} closeIconColor="black">
          <Trans i18n={i18n} i18nKey="common:snackbar.paid-ads-voucher">
            <Text
              variant="smallDescription"
              color="black"
              className={styles.snackbarText}
            >
              <span className={styles.voucherCode}></span>
            </Text>
          </Trans>
        </SnackBar>
      )
    }

    if (!currentUser) {
      return (
        <SnackBar className={styles.snackbar} closeIconColor='black'>
          <Trans
            i18n={i18n}
            i18nKey='common:snackbar.user-not-loggedin'
          >
            <Text variant='smallDescription' color='black' className={styles.snackbarText}>
              <Link
                onClick={() => {
                  tracker.trackEvent('snackbar_link', {
                    event_category: 'snackbar',
                    event_label: 'user_not_loggedin',
                  })
                }}
                className={styles.snackbarLink}
                href='/auth/signup'
              ></Link>
            </Text>
          </Trans>
        </SnackBar>
      )
    }

    if (snackbarVoucher) {
      return (
        <SnackBar className={styles.snackbar} closeIconColor="black">
          <Trans
            i18n={i18n}
            i18nKey="common:snackbar.voucher"
            values={{
              discount: discountValue,
              voucherCode: snackbarVoucher.code,
            }}
          >
            <Text
              variant="smallDescription"
              color="black"
              className={styles.snackbarText}
            >
              <span className={styles.voucherCode}></span>
            </Text>
          </Trans>
        </SnackBar>
      )
    }

    return (
      <SnackBar className={styles.snackbar} closeIconColor="black">
        <Trans
          i18n={i18n}
          i18nKey='common:snackbar.user-loggedin'
        >
          <Text variant='smallDescription' color='black' className={styles.snackbarText}>
            <Link
              onClick={() => {
                tracker.trackEvent('snackbar_link', {
                  event_category: 'snackbar',
                  event_label: 'user_loggedin',
                })
              }}
              className={styles.snackbarLink}
              href='/quiz/intro'
            ></Link>
          </Text>
        </Trans>
      </SnackBar>
    )
  }, [ready, mounted, i18n, local, router, tracker, currentUser])

  const getUserParams = () => {
    const queryParam = new URLSearchParams()

    if (currentUser) {
      queryParam.set('email', currentUser.email)
      queryParam.set('name', currentUser.name)
      queryParam.set('phone', currentUser.phoneNumber)
    }

    return queryParam.toString()
  }

  const getNativeNavbarProps = () => {
    if (/\/auth\/completing-signup/.test(router.pathname)) {
      return {
        title: 'Sign Up',
        showHamburger: false,
        showBackButton: true,
        backButtonLink: `/auth/signup?${getUserParams()}`,
      }
    }
    if (/\/auth\/signup/.test(router.pathname)) {
      return {
        showBaseLogo: true,
        showHamburger: false,
        showBackButton: false,
      }
    }
    if (/\/app\/account\/base-bff\/coupon/.test(router.pathname)) {
      return {
        title: 'COUPON CODE',
        showHamburger: false,
        showBackButton: true,
      }
    }
    if (/\/app\/account\/base-bff\/benefits/.test(router.pathname)) {
      return {
        title: 'BASE BFF TIER',
        showHamburger: false,
        showBackButton: true,
      }
    }
    if (/\/app\/account\/base-bff\/transaction/.test(router.pathname)) {
      return {
        title: 'TRANSACTIONS',
        showHamburger: false,
        showBackButton: true,
      }
    }
    if (/\/app\/account\/base-bff/.test(router.pathname)) {
      return { title: 'BFF', showHamburger: true, showBackButton: false }
    }
    if (/\/app\/cart/.test(router.pathname)) {
      return { title: 'My Cart', showHamburger: false, showBackButton: true }
    }
    if (/\/products-recommendation/.test(router.pathname)) {
      return {
        title: 'Your Routine',
        showHamburger: true,
        showCartLogo: true,
        showBackButton: true,
      }
    }
    if (/\/gift-card\/recipient-details/.test(router.pathname)) {
      return {
        title: 'Recipient Details',
        showHamburger: true,
        showBackButton: true,
      }
    }
    if (/\/gift-card\/overview/.test(router.pathname)) {
      return {
        title: 'Gift Card Overview',
        showHamburger: true,
        showBackButton: true,
      }
    }
  }

  const renderDisplayName = useCallback(() => {
    if (authUser?.name) return authUser.name.replace(/\s.*/, '')
    if (authUser?.email) return authUser.email.replace(/@.*/, '')
    return 'Sign In'
  }, [authUser])

  const isQuizIntroPage = router.pathname === '/quiz/intro'

  const doNotRenderNavbar = new RegExp(
    '(/app/order/.+)|' +
      '(^/quiz(?!/intro)/.+)|' + // match url /quiz/... except /quiz/intro
      '(/app-shell)|' +
      '(/shops)|' +
      '(/auth/reset-password/success-reset)|' +
      '(/auth/signup)'
  ).test(router.pathname)

  const isNativeNavbar = new RegExp(
    '(/auth/completing-signup)|' +
    '(/auth/signup)|' +
    '(/app/cart)|' +
      '(/products-recommendation)|' +
      '(/app/gift-card/.+)|' +
      '(/app/account/base-bff.*)'
  ).test(router.pathname)

  // UseEffects

  useEffect(() => {
    if (authUser && !doNotRenderNavbar) {
      asyncGetCurrentUser()
      asyncGetUserNotification()
      asyncGetCart()
      asyncGetDiscounts()
    }
  }, [
    authUser,
    doNotRenderNavbar,
    asyncGetCurrentUser,
    asyncGetUserNotification,
    asyncGetCart,
    asyncGetDiscounts,
  ])

  useEffect(() => {
    function refetchNavbarEventHandler() {
      if (authUser && !currentUser) asyncGetCurrentUser()
    }

    router.events.on('routeChangeComplete', refetchNavbarEventHandler)

    return () => {
      router.events.off('routeChangeComplete', refetchNavbarEventHandler)
    }
  }, [authUser, currentUser, router, asyncGetCurrentUser])

  useDidMount(() => {
    function snackEventHandler({ detail: { isShow } }) {
      setIsSnackbarHidden(!isShow)
    }

    setMounted(true)
    window.addEventListener('snack', snackEventHandler)

    return () => {
      setMounted(false)
      window.removeEventListener('snack', snackEventHandler)
    }
  })

  // Variables
  const isShowCategoryFilter = router.asPath === '/'
  const invertColor = !!navbarInvertColorUrls.find(
    pattern => pattern.test(router.asPath) || router.asPath === '/'
  )
  const userHasBFFData = Boolean(authUser?.isBFF)
  const displayUserName = renderDisplayName()

  const accountLinksWithCount =
    notifications.length > 0
      ? accountLinks.map(link => {
          const notification = notifications
            .filter(({ count }) => count > 0)
            .find(({ label }) => label === link.label)

          return { ...link, count: notification?.count || 0 }
        })
      : accountLinks
  const showBadge = !!accountLinksWithCount.find(({ count }) => count > 0)
  const cartItems =
    cart?.bundles?.reduce((currentCartItems, nextBundle) => {
      const existingBundleIndex = currentCartItems.findIndex(
        ({ code }) => code === nextBundle.code
      )

      if (existingBundleIndex >= 0) {
        currentCartItems[existingBundleIndex].quantity =
          parseInt(currentCartItems[existingBundleIndex].quantity) + 1
        return currentCartItems
      }

      const bundlePrice =
        nextBundle.products?.reduce(
          (totalPrice, product) => totalPrice + product.price,
          0
        ) || 0
      const discountAmount =
        nextBundle.discount?.amount ||
        (nextBundle.discount?.percentage / 100) * bundlePrice ||
        0

      return [
        ...currentCartItems,
        {
          ...nextBundle,
          type: 'bundle',
          quantity: 1,
          price: bundlePrice,
          discountedPrice: bundlePrice - discountAmount,
        },
      ]
    }, []) || []

  cartItems.push.apply(
    cartItems,
    cart?.items
      ?.filter(({ product }) => product.price !== 0)
      .map(({ product, quantity }) => {
        const { amount } = calculateDiscount(availableDiscounts, product, quantity)

        return {
          ...product,
          quantity,
          type: 'product',
          discountedPrice: product.price - amount,
        }
      }) || []
  )

  // Render

  if (doNotRenderNavbar) return null
  if (!ready && !mounted) return null

  return (
    <header
      className={`
        ${styles.header}
        ${
          isSnackbarHidden ||
          router.pathname === '/quiz/intro' ||
          router.pathname === '/auth/completing-signup' ||
          router.pathname === '/auth/reset-password/send' ||
          router.pathname === '/auth/reset-password/reset' ||
          router.pathname === '/auth/reset-password/success'
          ? styles.moveUp : ''
        }`
      }
      >
      {
        router.pathname !== '/quiz/intro'
        && router.pathname !== '/auth/completing-signup'
        && router.pathname !== '/auth/reset-password/send'
        && router.pathname !== '/auth/reset-password/reset'
        && router.pathname !== '/auth/reset-password/success'
        && renderSnackbar()}

      {mounted ? (
        <>
          {isLarge && (
            <NavbarDesktop
              t={t}
              linkOptions={links}
              accountLinkOptions={accountLinksWithCount}
              showBadge={showBadge}
              cartItems={cartItems}
              isUserLoggedIn={Boolean(authUser)}
              userHasBFFData={userHasBFFData}
              displayUserName={displayUserName}
              isRemovingItems={isRemovingItems}
              isQuizIntroPage={isQuizIntroPage}
              isSnackbarHidden={isSnackbarHidden}
              removeItemsFromCartHandler={removeItemsFromCartHandler}
              changeLanguage={changeLanguage}
              logout={internalLogout}
            />
          )}

          {!isLarge && (
            <NavbarMobile
              isNativeNavbar={isNativeNavbar}
              linkOptions={links}
              accountLinkOptions={accountLinksWithCount}
              invertColor={invertColor}
              {...getNativeNavbarProps()}
              cartItems={cartItems}
              isUserLoggedIn={Boolean(authUser)}
              isQuizIntroPage={isQuizIntroPage}
              displayUserName={displayUserName}
              activeSidebar={activeSidebar}
              isShowCategoryFilter={isShowCategoryFilter}
              mobileLinks={mobileLinks}
              toggleSidebar={toggleSidebar}
              changeLanguage={changeLanguage}
              logout={internalLogout}
            />
          )}
        </>
      ) : null}
    </header>
  )
}

export default Navbar
