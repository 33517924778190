import { useMemo } from 'react'
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'

import appConfig from '@config/app'
import { getLocalMethods } from '@utils/localContext'
import ApolloUtility, { APOLLO_STATE_PROP_NAME } from './util'

const utils = new ApolloUtility()

/**
 * createApolloCache
 *
 * @param {any} config
 * @returns apolloCache
 */
function createApolloCache(config) {
  return new InMemoryCache(config)
}

/**
 * createApolloClient
 *
 * @param {any} ctx
 * @returns apolloClient
 */
function createApolloClient(ctx) {
  const isSSR = typeof window === 'undefined'
  const uri =
    isSSR && appConfig.environment !== 'local' && appConfig.ssrApi
      ? appConfig.ssrApi
      : appConfig.api
  const localContext = getLocalMethods(ctx)

  return new ApolloClient({
    ssrMode: isSSR,
    link: ApolloLink.from([
      utils.createErrorLink(),
      new BatchHttpLink({
        uri,
        fetch: utils.createApolloFetchHandler(localContext),
        batchInterval: 100,
        batchDebounce: true,
      }),
    ]),
    cache: createApolloCache(utils.getInMemoryCacheConfig()),
  })
}

// save client side apollo instance
let apolloClient = null

/**
 *
 * @param {any} initialState
 * @param {any} ctx
 * @returns {ApolloClient}
 */
function initializeApollo(initialState, ctx) {
  const _apolloClient = apolloClient || createApolloClient(ctx)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.cache.extract()

    // Restore the cache with the merged data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

/**
 * useApollo hooks
 *
 * @param {import('next').NextPageContext} pageProps
 * @returns {ApolloClient}
 */
function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state, undefined), [state])
  return store
}

/**
 * addApolloState
 *
 * @param {ApolloClient} client
 * @param {import('next').GetStaticPropsResult<any> | import('next').GetServerSidePropsResult<any>} pageProps
 * @returns {import('next').GetStaticPropsResult<any> | import('next').GetServerSidePropsResult<any>}
 */
function addApolloState(client, pageProps) {
  if (pageProps.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export { initializeApollo, addApolloState }
export default useApollo
