import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import { useRouter } from 'next/router'
import debounce from 'debounce'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

import useLocalContext from '../localContext'

import { userLoginMutation } from '@gql/userMutation'
import { authenticateUser } from '@gql/userQuery'

dayjs.extend(isSameOrAfter)

// AUTH CONTEXT
/**
 * @typedef {Object} AuthenticatedUser
 * @property {String} id
 * @property {String} email
 * @property {String} phoneNumber
 * @property {String} name
 * @property {Boolean} isBFF
 *
 * @typedef {Object} AuthState
 * @property {Boolean} loading
 * @property {AuthenticatedUser} user
 *
 * @typedef {Object} AuthAction
 * @property {String} type
 * @property {Object} payload
 *
 * @typedef {Object} AuthContext
 * @property {AuthState} authState
 * @property {(email: String, password: String) => Promise<void>} login
 * @property {() => Promise<void>} logout
 * @property {() => Promise<Boolean>} redirectToSignin
 * @property {(param: Object) => Promise<Boolean>} redirectToSignUp
 * @property {() => Promise<void>} refreshAuthState
 */

/**
 * @type {Context<AuthContext>}
 */
const Auth = createContext()
Auth.displayName = 'Auth'

/**
 * @type {AuthState}
 */
const defaultState = {
  loading: true,
  user: null,
}

/**
 * @function authReducer
 * @param {AuthState} state
 * @param {AuthAction} action
 * @returns {AuthState}
 */
const authReducer = (state, action) => {
  switch (action.type) {
    case 'CONTINUE':
      return { loading: false, user: state.user }
    case 'LOADING':
      return { loading: true, user: state.user }
    case 'LOGGED_IN':
      return { loading: false, user: action.payload }
    case 'NOT_LOGGED_IN':
      return { loading: false, user: null }
    default:
      return { loading: true, user: null }
  }
}

/**
 * useAuth
 * @returns {AuthContext}
 */
const useAuth = () => useContext(Auth)

/**
 *
 * @param {{
 *  children: JSX.Element,
 *  pageProps: any,
 *  apolloClient: import('@apollo/client').ApolloClient
 * }} props
 *
 * @returns
 */
export const AuthProvider = ({ children, pageProps, apolloClient }) => {
  const local = useLocalContext()
  const router = useRouter()
  const mountRef = useRef(false)

  /**
   * @type {AuthState}
   */
  const initialState = pageProps['user']
    ? { loading: false, user: pageProps['user'] }
    : defaultState

  const [authState, authDispatch] = useReducer(authReducer, initialState)

  const _authenticate = useCallback(async () => {
    const { accessToken, tokenGenerateTime } = local.getTokens()

    if (!accessToken) {
      authDispatch({ type: 'NOT_LOGGED_IN' })
      return
    }

    if (tokenGenerateTime) {
      const tokenTimeAfterFiveMinutes = dayjs(
        decodeURIComponent(tokenGenerateTime)
      ).add(5, 'minutes')
      const timeNow = dayjs()

      if (timeNow.isBefore(tokenTimeAfterFiveMinutes) && authState.user) {
        authDispatch({ type: 'CONTINUE' })
        return
      }
    }

    try {
      const {
        data: { user },
      } = await apolloClient.query({
        query: authenticateUser,
        fetchPolicy: 'network-only',
      })

      authDispatch({ type: 'LOGGED_IN', payload: user })
    } catch {
      local.clear()
      authDispatch({ type: 'NOT_LOGGED_IN' })
    }
  }, [apolloClient, local, authState.user])

  const _refreshAuthState = useCallback(async () => {
    try {
      authDispatch({ type: 'LOADING' })

      const {
        data: { user },
      } = await apolloClient.query({
        query: authenticateUser,
        fetchPolicy: 'network-only',
      })

      authDispatch({ type: 'LOGGED_IN', payload: user })
    } catch {
      local.clear()
      authDispatch({ type: 'NOT_LOGGED_IN' })
    }
  }, [apolloClient, local])

  const _login = useCallback(
    async (email, password, source) => {
      const additionalLoginInfo = {
        ...local.getAnalyticsData(),
        ...local.getUtmData(),
        ...local.getTiktokData(),
        source: source || null,
      }

      const {
        data: { tokens },
      } = await apolloClient.mutate({
        mutation: userLoginMutation,
        variables: { email, password, additionalLoginInfo },
        fetchPolicy: 'network-only',
      })

      local.setAccessToken(tokens.accessToken)
      local.setRefreshToken(tokens.refreshToken)
      local.setTokenTime(tokens.tokenGenerateTime)

      await apolloClient.clearStore()

      const {
        data: { user },
      } = await apolloClient.query({
        query: authenticateUser,
        fetchPolicy: 'network-only',
      })

      authDispatch({ type: 'LOGGED_IN', payload: user })

      return router.push(router.query.r || '/app/quiz')
    },
    [apolloClient, local, router]
  )

  const _logout = useCallback(async () => {
    local.clear()

    try {
      await apolloClient.clearStore()
    } catch {
      // do nothing
    }

    router.pathname = router.pathname.split('[')[0]

    authDispatch({ type: 'NOT_LOGGED_IN' })
    if (router.pathname === '/404' ) {
      await router.replace(`/auth/signin`)
    } if (router.pathname === '/app/quiz/result/') {
      await router.replace(`/auth/signin?r=/app/quiz`)
    } else {
      await router.replace(`/auth/signin?r=${router.pathname}`)
    }
    router.reload()
  }, [apolloClient, local, router])

  const _redirectToSignin = useCallback(() => {
    router.pathname = router.pathname.split('[')[0]
    return router.replace(`/auth/signin?r=${router.pathname}`)
  }, [router])

  const _redirectToSignUp = useCallback(
    param => {
      const queryParam = new URLSearchParams()

      if (typeof param === 'object' && !Array.isArray(param)) {
        Object.keys(param).forEach(key => {
          queryParam.set(key, param[key])
        })
      }
      queryParam.set('r', router.pathname)

      return router.replace(`/auth/signup?${queryParam.toString()}`)
    },
    [router]
  )

  useEffect(() => {
    if (mountRef.current === false) {
      mountRef.current = true
      _authenticate()
    }
  }, [_authenticate])

  useEffect(() => {
    const routeHandler = debounce(async () => {
      authDispatch({ type: 'LOADING' })
      await _authenticate()
    }, 100)

    router.events.on('routeChangeStart', routeHandler)

    return () => {
      router.events.off('routeChangeStart', routeHandler)
    }
  }, [router, _authenticate])

  return (
    <Auth.Provider
      value={{
        authState,
        login: _login,
        logout: _logout,
        redirectToSignin: _redirectToSignin,
        redirectToSignUp: _redirectToSignUp,
        refreshAuthState: _refreshAuthState,
      }}
    >
      {children}
    </Auth.Provider>
  )
}

export default useAuth
