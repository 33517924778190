/**
 * calculateDiscount
 *
 * helper to calculate discount product
 *
 * @param {any[]} availableDiscounts
 * @param {any} product
 * @returns {{
 *  amount: number
 *  percentage: number
 * }}
 */
function calculateDiscount(availableDiscounts = [], product, quantity, isFromProductCatalog = false) {
  const discountData = availableDiscounts
    .filter(discount => discount.type !== 'BuyAmountGetAmount' && discount.type !== 'BuyAmountGetProduct')
    .reduce(
      (data, nextDiscount) => {

        let calculateBy = nextDiscount.amount ? 'amount' : 'percentage'
        calculateBy = !nextDiscount.amount && !nextDiscount.percentage ? 'tiered_discount' : calculateBy

        const checkProductId = nextDiscount.productIds?.find(
          id => id == product.id
        )
        const checkSubcategoryId = nextDiscount.subcategoryIds?.find(
          id => id == product.subcategory.id
        )

        if (!checkProductId && !checkSubcategoryId && nextDiscount.type !== "General") return data

        switch (calculateBy) {
          case 'amount':
            data.amount = data.amount + nextDiscount[calculateBy]
            break
          case 'percentage':
            data.amount =
              data.amount + (nextDiscount[calculateBy] / 100) * product.price
            break
          case 'tiered_discount': {
            data.isTieredDiscount = true
            if (isFromProductCatalog) {
              data.percentage = nextDiscount.discountTiers[nextDiscount.discountTiers.length - 1].percentage
              data.amount = data.amount + (data.percentage / 100) * product.price
              return data
            }
            const discountTier = nextDiscount.discountTiers.reduce((res, nextItem, currentIndex) => {
              if (quantity >= nextItem.minQuantity) {
                res = { ...res, ...nextItem }
                if (currentIndex < nextDiscount.discountTiers.length - 1) {
                  res = {
                    ...res,
                    nextTier: nextDiscount.discountTiers[currentIndex + 1],
                  }
                } else {
                  res = {
                    ...res,
                    nextTier: null,
                  }
                }
              }

              return res
            }, { nextTier: nextDiscount.discountTiers[0] })

            data.amount = data.amount + (discountTier.percentage / 100) * product.price
            data.nextTier = discountTier.nextTier
            break
          }
        }

        return data
      },
      { amount: 0, percentage: 0 }
    )

  if (discountData.amount > 0) {
    discountData.percentage = Math.round(
      (discountData.amount / product.price) * 100
    )
  }
  return discountData
}

export default calculateDiscount
