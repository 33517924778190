import { memo } from 'react'
import PropTypes from 'prop-types'

import styles from './style.module.css'

function Text({
  tag,
  variant,
  fontWeight,
  color,
  className,
  striked,
  margin,
  children,
}) {
  const ElementTag = `${tag}`

  const classNames =
    `${styles[variant]} ` +
    `${color && styles[color]} ` +
    `${fontWeight && styles[`font-${fontWeight}`]} ` +
    `${striked && styles.striked} ` +
    `${className}`

  const marginObj = {
    marginLeft: margin?.left ? `${margin.left}px` : undefined,
    marginRight: margin?.right ? `${margin.right}px` : undefined,
    marginTop: margin?.top ? `${margin.top}px` : undefined,
    marginBottom: margin?.bottom ? `${margin.bottom}px` : undefined,
  }

  return (
    <ElementTag className={classNames} style={marginObj}>
      {children}
    </ElementTag>
  )
}

Text.propTypes = {
  tag: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'div',
  ]),
  color: PropTypes.oneOf([
    'white',
    'black',
    'primary',
    'gray',
    'gray-02',
    'darkPeach',
    'green',
    'red',
    'lavender',
    'greenLime',
    'greenYellow',
    'dodgerBlue',
    'deepBlue',
    'hotPink',
  ]),
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.string,
  className: PropTypes.string,
  striked: PropTypes.bool,
  margin: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    bottom: PropTypes.number,
  }),
  children: PropTypes.node,
}

Text.defaultProps = {
  tag: 'p',
  variant: 'description',
}

export default memo(Text)
