import { memo, forwardRef } from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'

import styles from './style.module.css'

const FlatButton = forwardRef(
  (
    {
      link,
      externalLink,
      className,
      disabled,
      shallow,
      onClick,
      type,
      children,
      ...props
    },
    ref
  ) => {
    const classNames =
      `${styles.buttonFlat} ` +
      `${disabled ? styles.disabled : ''} ` +
      `${className || ''}`

    if (link) {
      return (
        <Link
          href={!disabled ? link : '#0'}
          shallow={shallow}
          className={classNames}
          onClick={!disabled ? onClick : undefined}
          ref={ref}
          {...props}
        >
          {children}
        </Link>
      )
    }

    if (externalLink) {
      return (
        <a
          href={!disabled ? externalLink : '#0'}
          className={classNames}
          onClick={!disabled ? onClick : undefined}
          ref={ref}
          {...props}
        >
          {children}
        </a>
      )
    }

    const ButtonElement = type ? 'button' : 'div'

    return (
      <ButtonElement
        type={type}
        className={classNames}
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
        ref={ref}
        target='_blank'
        {...props}
      >
        {children}
      </ButtonElement>
    )
  }
)

FlatButton.displayName = 'FlatButton'

/**
 * @typedef {React.ForwardRefExoticComponent<FlatButtonPropTypes & React.RefAttributes<HTMLElement>>} FlatButtonComponent
 */

/**
 * @typedef {Object} FlatButtonPropTypes
 * @property {String} link
 * @property {String} externalLink
 * @property {String} className
 * @property {Boolean} disabled
 * @property {Boolean} shallow
 * @property {Function} onClick
 * @property {'button' | 'reset' | 'submit' | undefined} type
 * @property {React.ReactNode} children
 */
FlatButton.propTypes = {
  link: PropTypes.string,
  externalLink: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  shallow: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  children: PropTypes.node,
}

/**
 * @type {React.MemoExoticComponent<FlatButtonComponent>}
 */
export default memo(FlatButton)
