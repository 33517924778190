import gql from 'graphql-tag'

import { Card } from './fragments'

export const userLoginMutation = gql`
  mutation userLoginMutation(
    $email: String!
    $password: String!
    $data: AdditionalLoginInfoInput
  ) {
    tokens: login(email: $email, password: $password, data: $data) {
      accessToken
      refreshToken
      tokenGenerateTime
    }
  }
`

export const userFacebookLoginMutation = gql`
  mutation userFacebookLoginMutation(
    $token: String!
    $data: AdditionalLoginInfoInput
  ) {
    tokens: facebookLogin(token: $token, data: $data) {
      accessToken
      refreshToken
      tokenGenerateTime
    }
  }
`

export const userGoogleLoginMutation = gql`
  mutation userGoogleLoginMutation(
    $token: String!
    $data: AdditionalLoginInfoInput
  ) {
    tokens: googleLogin(token: $token, data: $data) {
      accessToken
      refreshToken
      tokenGenerateTime
    }
  }
`

export const userRegisterMutation = gql`
  mutation userRegisterMutation(
    $email: String!
    $name: String!
    $phone: String!
    $password: String!
    $data: AdditionalLoginInfoInput
  ) {
    tokens: register(
      email: $email
      name: $name
      phone: $phone
      password: $password
      data: $data
    ) {
      accessToken
      refreshToken
      tokenGenerateTime
    }
  }
`

export const userCreateMutation = gql`
  mutation userCreateMutation(
    $email: String!
    $gender: UserGenderType!
    $birthday: String!
  ) {
    tokens: createUser(
      email: $email
      gender: $gender
      birthday: $birthday
      source: "quiz"
    ) {
      accessToken
      refreshToken
      tokenGenerateTime
    }
  }
`

export const userUpdateMutation = gql`
  mutation userUpdateMutation(
    $name: String
    $gender: UserGenderType
    $phoneNumber: String
    $birthday: String
  ) {
    currentUser: updateUser(
      name: $name
      gender: $gender
      phoneNumber: $phoneNumber
      birthday: $birthday
    ) {
      id
      email
      name
      phoneNumber
      birthday
      gender
    }
  }
`

export const userUpdatePasswordMutation = gql`
  mutation userUpdatePasswordMutation(
    $currentPassword: String!
    $newPassword: String!
  ) {
    updatePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      id
      email
    }
  }
`

export const addOrUpdateBFFDataMutation = gql`
  mutation addOrUpdateBFFDataMutation(
    $joinMotivationAnswers: [String]!
    $idCardImage: String!
    $bankProvider: String!
    $bankAccountName: String!
    $bankAccountNumber: String!
    $bankBranch: String
    $joinCode: String
    $productSelection: String
  ) {
    currentUser: addOrUpdateBaseFriendUserData(
      joinMotivationAnswers: $joinMotivationAnswers
      idCardImage: $idCardImage
      bankProvider: $bankProvider
      bankAccountName: $bankAccountName
      bankAccountNumber: $bankAccountNumber
      bankBranch: $bankBranch
      joinCode: $joinCode
      productSelection: $productSelection
    ) {
      id
      email
    }
  }
`

export const refreshAccessTokenMutation = gql`
  mutation refreshAccessTokenMutation(
    $refreshToken: String!
    $data: AdditionalLoginInfoInput
  ) {
    tokens: refreshAccessToken(refreshToken: $refreshToken, data: $data) {
      accessToken
      refreshToken
      tokenGenerateTime
    }
  }
`

export const subscribeNewsletterMutation = gql`
  mutation subscribeNewsletterMutation($email: String!) {
    subscribeNewsletter(email: $email)
  }
`

export const sendResetPasswordEmailMutation = gql`
  mutation sendResetPasswordEmailMutation($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`

export const resetPasswordMutation = gql`
  mutation resetPasswordMutation($token: String!, $newPassword: String!) {
    tokens: resetPassword(token: $token, newPassword: $newPassword) {
      accessToken
      refreshToken
      tokenGenerateTime
    }
  }
`

export const removeUserCardMutation = gql`
  mutation removeUserCardMutation($id: String!) {
    removeUserCardById(id: $id) {
      id
      email
      cards {
        ...Card
      }
    }
  }
  ${Card}
`

export const verifyOTPMutation = gql`
  mutation verifyPhoneNumberVerificationCode($OTP: String!) {
    verifyPhoneNumberVerificationCode(otpCode: $OTP) {
      id
      email
      isPhoneVerified
    }
  }
`

export const verifyEmailVerificationCode = gql`
  mutation verifyEmailVerificationCode($otpCode: String!) {
    verifyEmailVerificationCode(otpCode: $otpCode)
  }
`

export const updateUserWithPassword = gql`
  mutation updateUserWithPassword(
    $name: String!
    $phone: String!
    $password: String!
  ) {
    updateUserWithPassword(
      name: $name
      phoneNumber: $phone
      password: $password
    ) {
      id
      email
    }
  }
`

export const sendPhoneNumberOTPCode = gql`
  mutation sendPhoneNumberOTPCode {
    sendPhoneNumberOTPCode
  }
`

export const sendVerificationEmail = gql`
  mutation sendVerificationEmail {
    sendVerificationEmail
  }
`
