import useDidMount from './useDidMount'
import { cancelablePromise } from '../helpers'

/**
 * @function useScrollBehaviorModule
 * custom hook to load scroll behavior polyfills
 *
 * @returns {Void}
 */
function useScrollBehaviorModule() {
  useDidMount(() => {
    if (document.documentElement.style.scrollBehavior) {
      const scrollBehaviorImport = cancelablePromise(
        import(
          /* webpackChunkName: "scroll-behavior-module" */ 'scroll-behavior-polyfill'
        )
      )

      scrollBehaviorImport.promise
        .then(() => {})
        .catch(error => {
          // Handle if module importing is failed
          // with other than cancelled reason
          if (!error.isCanceled) {
            //
          }
        })

      return () => {
        scrollBehaviorImport.cancel()
      }
    }
  })
}

export default useScrollBehaviorModule
