export default {
  expires: 30 * 24 * 60 * 60, // 30 days
  utmExpires: 30 * 24 * 60 * 60, // 30 days
  langExpires: 2 * 60 * 60, // 2 hours
  // keys
  cookieKeys: {
    accessToken: 'base_access_token',
    refreshToken: 'base_refresh_token',
    tokenGenerateTime: 'base_token_time',
    utmSource: 'utm_source',
    utmMedium: 'utm_medium',
    utmCampaign: 'utm_campaign',
    optimizeExpId: '_opt_expId',
    optimizeExpVar: '_opt_expVar',
    gclid: '_gclid',
    fbp: '_fbp',
    fbc: '_fbc',
    ga: '_ga',
    language: 'lang',
    ttp: '_ttp',
    ttclid: 'ttclid',
    authWindowReloaded: '_auth_window_reloaded',
  },
}
