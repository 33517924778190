import gql from 'graphql-tag'

import { CartData, GiftCardCartData } from './fragments'

export const getCart = gql`
  query getCart($orderType: OrderTypeType) {
    cart(orderType: $orderType) {
      ...CartData
    }
  }
  ${CartData}
`

export const getCartGiftCard = gql`
  query getCartGiftCard {
    cart: cartGiftCard {
      ...GiftCardCartData
    }
  }
  ${GiftCardCartData}
`
