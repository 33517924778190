import * as Sentry from '@sentry/nextjs'

export function setUser(userData) {
  const scope = Sentry.getCurrentScope()
  const { id, email, ...extraData } = userData
  scope.setUser({ id, email })
  scope.setContext('currentUserData', extraData)
}

export function unsetUser() {
  const scope = Sentry.getCurrentScope()
  scope.setUser(null)
  scope.setContext('currentUserData', null)
}
