import { useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'
import Image from 'next/legacy/image'
import PropTypes from 'prop-types'

import styles from './style.module.css'

function SnackBar({
  className,
  closeIconColor,
  children,
  closeHandler,
  closeable,
}) {
  const [mounted, setMounted] = useState(false)
  const [isClosed, setClosed] = useState(false)

  const internalCloseHandler = useCallback(() => {
    const hideSnackEvent = new CustomEvent('snack', {
      detail: { isShow: false },
    })
    window.dispatchEvent(hideSnackEvent)

    if (closeHandler) closeHandler()

    setClosed(true)

    const snackBarContainerEl = document.getElementById('snack-bar')
    snackBarContainerEl.style.display = 'none'
  }, [closeHandler])

  useEffect(() => {
    const snackBarContainerEl = document.getElementById('snack-bar')

    snackBarContainerEl.style.display = 'block'
    setMounted(true)
    const showSnackEvent = new CustomEvent('snack', {
      detail: { isShow: true },
    })
    window.dispatchEvent(showSnackEvent)

    return () => {
      snackBarContainerEl.style.display = 'none'
      setMounted(false)
      const hideSnackEvent = new CustomEvent('snack', {
        detail: { isShow: false },
      })
      window.dispatchEvent(hideSnackEvent)
    }
  }, [])

  return mounted && !isClosed
    ? createPortal(
        <div className={`${styles.container} ${className}`}>
          {children}
          {closeable && (
            <div className={styles.closeIcon}>
              <Image
                alt="close snackbar"
                src={`https://static.base.co.id/clear-${closeIconColor}.svg`}
                layout="responsive"
                height={48}
                width={48}
                onClick={internalCloseHandler}
              />
            </div>
          )}
        </div>,
        document.getElementById('snack-bar')
      )
    : null
}

SnackBar.propTypes = {
  className: PropTypes.string,
  closeIconColor: PropTypes.string,
  closeHandler: PropTypes.func,
  children: PropTypes.node,
  closeable: PropTypes.bool,
}

SnackBar.defaultProps = {
  closeIconColor: 'white',
  closeable: true,
}

export default SnackBar
