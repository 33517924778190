const links = [
  // { label: 'Home', url: '/', onlyDesktop: true },
  // { label: 'Shop Products', url: '/products' },
  { label: 'Sustainability', url: '/greencollective' },
  // { label: `New Launch`, url: '/newlaunch/face-mist-gel-mask', className: 'campaign' },
  { label: 'Blog', url: '/blog' },
  { label: 'BFF', url: '/bff' },
]

const accountLinks = [
  {
    label: 'Account Details',
    url: '/app/account/profile' ,
    image: 'https://static.base.co.id/icon-account-grey.svg',
  },
  {
    label: 'Order',
    url: '/app/account/order',
    image: 'https://static.base.co.id/icon-cart-grey.svg',
  },
  {
    label: 'Skin Profile',
    url: '/app/account/skin',
    image: 'https://static.base.co.id/icon-address-book-grey.svg',
  },
  {
    label: ' Product Recommendation',
    url: '/app/account/product',
    image: 'https://static.base.co.id/icon-thumbs-grey.svg',
  },
  {
    label: 'Card Payment',
    url: '/app/account/payment',
    image: 'https://static.base.co.id/icon-payment-grey.svg',
  },
]

const mobileLinks = [
  // { label: 'Home', url: '/', onlyDesktop: true },
  { label: 'Shop Products', url: '/products', image: 'https://static.base.co.id/icon-cart-mobile.svg' },
  { label: 'Sustainability', url: '/greencollective', image: 'https://static.base.co.id/icon-cart-sustainability.svg' },
  // { label: `New Launch`, url: '/newlaunch/face-mist-gel-mask', className: 'campaign' },
  { label: 'Blog', url: '/blog', image: 'https://static.base.co.id/icon-blog-mobile.svg' },
  { label: 'BFF', url: '/bff', image: 'https://static.base.co.id/icon-bff-mobile.svg' },
]

export { links, accountLinks, mobileLinks }
