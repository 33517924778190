import gql from 'graphql-tag'

export const Article = gql`
  fragment Article on ArticleType {
    id
    title
    titleSlug
    image
    imageAlt
    metadata
    jsonSchema
    content
    tags
    isFeatured
    categoriesSubcategories {
      category {
        id
        name
      }
      subcategory {
        id
        name
      }
    }
  }
`

export const BundleIdentity = gql`
  fragment BundleIdentity on BundleType {
    id
    code
    name
  }
`

export const Bundle = gql`
  fragment Bundle on BundleType {
    id
    code
    name
    description
    shortDescription
    catalogDescription
    productSlug
    image
    images
    userAnswerId
    benefits
    howToUse {
      image
      description
    }
    skinTypes {
      name
      icon
    }
    features {
      image
      title
      description
    }
  }
`

export const Card = gql`
  fragment Card on CardType {
    id
    cardNumber
    cardToken
    cardType
  }
`

export const CartExtra = gql`
  fragment CartExtra on CartExtraType {
    id
    type
    amount
    description
  }
`

export const Category = gql`
  fragment Category on CategoryType {
    id
    name
  }
`

export const Discount = gql`
  fragment Discount on DiscountType {
    id
    code
    type
    name
    percentage
    amount
    flashSale
    discountTiers {
      minQuantity
      percentage
    }
  }
`

export const Error = gql`
  fragment Error on ErrorType {
    code
    description
  }
`

export const GiftCard = gql`
  fragment GiftCard on GiftCardType {
    id
    category
    name
    icon
    image
  }
`

export const GiftCardDetail = gql`
  fragment GiftCardDetail on GiftCardExtraDetailType {
    id
    recipientEmail
    recipientName
    senderName
    message
    deliveredAt
  }
`

export const Ingredient = gql`
  fragment Ingredient on IngredientType {
    id
    code
    name
    inci
    tag
    description
    image
    sourceCity
    sourceCountry
    isActiveIngredient
  }
`

export const Option = gql`
  fragment Option on OptionType {
    id
    title
    description
    image
  }
`

export const OptionsOrder = gql`
  fragment OptionsOrder on OptionsOrderType {
    id
    order
    goToQuestionsOrderId
    overrideGoToQuestionsOrderId
  }
`

export const Order = gql`
  fragment Order on OrderType {
    id
    type
    userAnswerId
    status
    readyToReview
    isCompletelyReviewed
    showOrderAgain
    createdAt
  }
`

export const OrderInvoices = gql`
  fragment OrderInvoices on InvoiceType {
    id
    status
    invoiceNumber
    subtotalAmount
    totalAmount
  }
`

export const OrderInvoicesItems = gql`
  fragment OrderInvoicesItems on InvoiceItemType {
    type
    quantity
    amount
    description
  }
`

export const Address = gql`
  fragment Address on AddressType {
    id
    name
    recipientName
    phoneNumber
    addressLine1
    addressLine2
    city
    province
    country
    postalCode
    isDefault
  }
`

export const OrderTransactions = gql`
  fragment OrderTransactions on TransactionType {
    id
    status
    paymentMethod
    # Credit card
    card {
      id
      cardNumber
      cardType
    }
    cardNumber
    # VA or Bank Transfer
    virtualAccountBank
    virtualAccountNumber
    virtualAccountExpiryDurationInMillis
    bankTransferExpiryDurationInMillis
    # Gopay
    gopayQrCode
    gopayDeeplinkUrl
    # Dana
    danaCheckoutUrl
    # Shopeepay
    shopeepayDeeplinkUrl
    # Kredivo
    kredivoRedirectUrl
    # Akulaku
    akulakuRedirectUrl
    # Alfamart / Indomaret
    counterStoreType
    counterStorePaymentCode
    createdAt
  }
`

export const OrderLogs = gql`
  fragment OrderLogs on OrderLogType {
    status
    description
    createdAt
  }
`

export const OrderDeliveries = gql`
  fragment OrderDeliveries on DeliveryType {
    id
    status
    awbNumber
    providerName
    checkpoints {
      description
      createdAt
    }
  }
`

export const ProductIdentity = gql`
  fragment ProductIdentity on ProductType {
    id
    name
  }
`

export const ProductSimple = gql`
  fragment ProductSimple on ProductType {
    id
    name
    price
  }
`

export const Product = gql`
  fragment Product on ProductType {
    id
    sku
    skuAggregate
    skuVariant
    name
    variant
    description
    shortDescription
    catalogDescription
    productSlug
    image
    allImages
    price
    isBundleOnly
    bundleQuantity
    listingStatus
    bundleQuantity
    benefits
    howToUse {
      image
      description
    }
    displayAsNotAvailable
    skinTypes {
      name
      icon
    }
    features {
      title
      description
      image
    }
    displayName
    displayImage
  }
`

export const ProductInfo = gql`
  fragment ProductInfo on ProductType {
    id
    name
    image
    description
    variant
    displayImage
  }
`

export const ProductScore = gql`
  fragment ProductScore on ScoreType {
    averageScore
    scoreCount
  }
`

export const Question = gql`
  fragment Question on QuestionType {
    id
    type
    title
    description
    maxChoice
    answerType
    postTransitionText
    icon
  }
`

export const QuizAnswerInfo = gql`
  fragment QuizAnswerInfo on QuizAnswerType {
    id
    userDisplayName
    userGender
    userAge
    createdAt
  }
`

export const QuizAnswerResult = gql`
  fragment QuizAnswerResult on QuizResultType {
    id
    title
    description
    image
    questionGroupName
  }
`

export const Subcategory = gql`
  fragment Subcategory on SubcategoryType {
    id
    name
    bundleQuantity
  }
`

export const UserIdentity = gql`
  fragment UserIdentity on UserType {
    id
    email
    name
    isPhoneVerified
  }
`

export const UserBFFData = gql`
  fragment UserBFFData on BaseFriendUserDataType {
    id
    createdAt
    voucherId
    voucherCode
    voucherUsage
    totalCommission
    tier
    tierLogs {
      id
      tier
      bffUserId
    }
    pendingCommissionLogs {
      id
      orderId
      pendingCommission
      redemptionDate
      createdAt
    }
    paidComissionLogs {
      id
      amount
      transferProof
      transferDate
    }
    voucherRedemptions {
      id
      createdAt
    }
  }
`

export const UserPointData = gql`
  fragment UserPointData on UserPoints {
    id
    type
    point
    createdAt
    pointLogs {
      id
      orderId
      type
      prevPoint
      currentPoint
      createdAt
    }
  }
`

export const Voucher = gql`
  fragment Voucher on VoucherType {
    id
    code
  }
`

// Combined fragment

export const CartData = gql`
  fragment CartData on CartType {
    id
    cartType
    userAnswerId
    subtotalAmount
    deliveryCost
    taxAmount
    totalAmount

    # Cart Items
    items {
      quantity
      userAnswerId
      product {
        ...Product
        subcategory {
          ...Subcategory
        }
      }
      error {
        ...Error
      }
    }

    # Cart Bundles
    bundles {
      ...Bundle
      error {
        ...Error
      }
      discount {
        ...Discount
      }
      products {
        ...Product
      }
    }

    # Cart Discounts
    discounts {
      ...Discount
    }

    # Cart Vouchers
    voucher {
      ...Voucher
      discount {
        ...Discount
      }
      error {
        ...Error
      }
    }

    # Cart Extra
    extras {
      ...CartExtra
    }
  }
  ${Product}
  ${Bundle}
  ${Error}
  ${Discount}
  ${Subcategory}
  ${CartExtra}
  ${Voucher}
`

export const GiftCardCartData = gql`
  fragment GiftCardCartData on CartType {
    id
    cartType

    extras {
      ...CartExtra

      giftCardDetail {
        ...GiftCardDetail

        giftCard {
          ...GiftCard
        }
      }
    }
  }
  ${CartExtra}
  ${GiftCardDetail}
  ${GiftCard}
`
