import gql from 'graphql-tag'

import { CartData, GiftCardCartData } from './fragments'

export const addItemsToCartMutation = gql`
  mutation addItemsToCartMutation(
    $items: [CartItemInputType]!
    $orderType: OrderTypeType
    $userAnswerId: String
    $source: CartItemSourceType
  ) {
    cart: addItemsToCart(
      items: $items
      orderType: $orderType
      userAnswerId: $userAnswerId
      source: $source
    ) {
      ...CartData
    }
  }
  ${CartData}
`

export const addBundleToCartMutation = gql`
  mutation addBundleToCartMutation(
    $bundles: [BundleItemInputType]!
    $orderType: OrderTypeType
    $userAnswerId: String
    $source: CartItemSourceType
  ) {
    cart: addBundleToCart(
      bundles: $bundles
      orderType: $orderType
      userAnswerId: $userAnswerId
      source: $source
    ) {
      ...CartData
    }
  }
  ${CartData}
`

export const addGiftCardToCartMutation = gql`
  mutation addGiftCardToCartMutation(
    $extras: [CartExtraInputType]!
    $orderType: OrderTypeType
    $giftCardId: String
  ) {
    cart: addExtrasToCart(
      extras: $extras
      orderType: $orderType
      cartType: GiftCard
      giftCardId: $giftCardId
    ) {
      ...GiftCardCartData
    }
  }
  ${GiftCardCartData}
`

export const addVoucherToCartMutation = gql`
  mutation addVoucherToCartMutation($code: String!, $orderType: OrderTypeType) {
    cart: addVoucherCodeToCart(code: $code, orderType: $orderType) {
      ...CartData
    }
  }
  ${CartData}
`

export const addExtrasToCartMutation = gql`
  mutation addExtrasToCartMutation(
    $extras: [CartExtraInputType]!
    $orderType: OrderTypeType
  ) {
    cart: addExtrasToCart(
      extras: $extras
      orderType: $orderType
      cartType: Normal
    ) {
      ...CartData
    }
  }
  ${CartData}
`

export const removeItemsFromCartMutation = gql`
  mutation removeItemsFromCartMutation(
    $items: [CartItemInputType]!
    $orderType: OrderTypeType
  ) {
    cart: removeItemsFromCart(items: $items, orderType: $orderType) {
      ...CartData
    }
  }
  ${CartData}
`

export const removeBundlesFromCartMutation = gql`
  mutation removeBundlesFromCartMutation(
    $bundles: [BundleItemInputType]!
    $orderType: OrderTypeType
  ) {
    cart: removeBundlesFromCart(bundles: $bundles, orderType: $orderType) {
      ...CartData
    }
  }
  ${CartData}
`

export const removeGiftCardToCartMutation = gql`
  mutation removeGiftCardToCartMutation(
    $extras: [CartExtraInputType]!
    $orderType: OrderTypeType
  ) {
    cart: removeExtrasFromCart(
      extras: $extras
      orderType: $orderType
      cartType: GiftCard
    ) {
      ...GiftCardCartData
    }
  }
  ${GiftCardCartData}
`

export const removeVoucherFromCartMutation = gql`
  mutation removeVoucherFromCartMutation(
    $code: String!
    $orderType: OrderTypeType
  ) {
    cart: removeVoucherCodeFromCart(code: $code, orderType: $orderType) {
      ...CartData
    }
  }
  ${CartData}
`

export const removeExtrasFromCartMutation = gql`
  mutation removeExtrasFromCartMutation(
    $extras: [CartExtraInputType]!
    $orderType: OrderTypeType
  ) {
    cart: removeExtrasFromCart(
      extras: $extras
      orderType: $orderType
      cartType: Normal
    ) {
      ...CartData
    }
  }
  ${CartData}
`

export const addOrUpdateGiftCardDetailMutation = gql`
  mutation addOrUpdateGiftCardDetailMutation(
    $giftCardId: String!
    $recipientEmail: String
    $recipientName: String
    $senderName: String
    $message: String
    $deliveredAt: String
  ) {
    cart: addOrUpdategiftCardDetail(
      giftCardId: $giftCardId
      recipientEmail: $recipientEmail
      recipientName: $recipientName
      senderName: $senderName
      message: $message
      deliveredAt: $deliveredAt
    ) {
      ...GiftCardCartData
    }
  }
  ${GiftCardCartData}
`
