export default {
  // SERVER ENV
  ssrApi: process.env.SSR_API_ENDPOINT,
  // SERVER & CLIENT ENV
  environment: process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT || 'local',
  languageChoices: ['en', 'id'],
  // CLIENT ENV
  api: process.env.NEXT_PUBLIC_API_ENDPOINT,
  qiscusAppId: process.env.NEXT_PUBLIC_QISCUS_APP_ID,
  qiscusWidgetChannelId: process.env.NEXT_PUBLIC_QISCUS_WIDGET_CHANNEL_ID,
  qontakAppId: process.env.NEXT_PUBLIC_QONTAK_APP_ID,
  qontakAppCode: process.env.NEXT_PUBLIC_QONTAK_APP_CODE,
  trackingMeasurementId: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
  facebookClientId: process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID,
  googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  optimizeExperimentId: process.env.NEXT_PUBLIC_OPTIMIZE_EXPERIMENT_ID,
}
