import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/core'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'
dayjs.extend(isSameOrAfter)

class ApolloUtility {
  /**
   * removeUndefinedObjectMembers
   *
   * remove any object keys/property with undefined value
   *
   * @param {any} value
   * @returns object
   */
  _removeUndefinedObjectMembers(value) {
    if (typeof value === 'undefined') return
    if (value === null) return null
    if (typeof value !== 'object' && !Array.isArray(value)) return value

    if (Array.isArray(value)) {
      return value.map(i => this._removeUndefinedObjectMembers(i))
    }

    const keys = Object.getOwnPropertyNames(value)
    return keys.reduce((res, key) => {
      if (typeof value[key] === 'undefined') return res
      return {
        ...res,
        [key]: this._removeUndefinedObjectMembers(value[key]),
      }
    }, {})
  }

  /**
   * generateFieldPaginationHandler
   *
   * modify data returned from graphql for paginated queries
   *
   * @param {string} paginatedArrayField field to be paginated
   * @returns object
   */
  _generateFieldPaginationHandler(paginatedArrayField) {
    return {
      read: existing => existing,
      merge: (
        existing,
        incoming,
        { args: { after, ...args } = {}, readField }
      ) => {
        const nonPaginationArgs = this._removeUndefinedObjectMembers(args)

        if (
          !existing ||
          JSON.stringify(existing.__args) !== JSON.stringify(nonPaginationArgs)
        ) {
          return {
            __args: nonPaginationArgs,
            ...incoming,
          }
        }

        if (!incoming || !incoming[paginatedArrayField].length) return existing

        const existingIdIndex = existing[paginatedArrayField].findIndex(
          ref =>
            readField('id', ref) ===
            readField('id', incoming[paginatedArrayField][0])
        )
        return {
          ...existing,
          __args: nonPaginationArgs,
          meta: incoming.meta,
          [paginatedArrayField]: [
            ...(existingIdIndex !== -1
              ? (existing[paginatedArrayField] || []).slice(0, existingIdIndex)
              : existing[paginatedArrayField] || []),
            ...(incoming[paginatedArrayField] || []),
            ...(existingIdIndex !== -1 &&
            existingIdIndex + (incoming[paginatedArrayField] || []).length <
              (existing[paginatedArrayField] || []).length - 1
              ? (existing[paginatedArrayField] || []).slice(
                  existingIdIndex + (incoming[paginatedArrayField] || []).length
                )
              : []),
          ],
        }
      },
    }
  }

  /**
   * _sendErrorToSentry
   *
   * @param {string} operationName
   * @param {any} variables
   * @param {import('graphql').GraphQLError[]} graphQLErrors
   * @param {any} networkError
   */
  _sendErrorToSentry(
    operationName,
    variables = {},
    graphQLErrors = [],
    networkError
  ) {
    if (networkError) {
      Sentry.captureException(
        new Error(`GraphQL Network Error: ${networkError.code}`),
        {
          contexts: {
            graphql: {
              operationName,
              variables,
            },
          },
          extra: { networkError },
        }
      )
    }

    // Filter graphql errors,
    const filteredErrors = graphQLErrors
      .filter(
        err =>
          err.code !== 'Unauthorized' &&
          err.code !== 'BadRequest' &&
          err.code !== 'NotFound'
      )
      .reduce((obj, nextErr, index) => {
        obj[`err_${index}`] = nextErr
        return obj
      }, {})

    if (filteredErrors.length > 0) {
      // send batch errors as one exception
      Sentry.captureException(new Error('GraphQL Error'), {
        contexts: {
          graphql: {
            operationName,
            variables,
          },
        },
        extra: { ...filteredErrors },
      })
    }
  }

  /**
   * getInMemoryCacheConfig
   */
  getInMemoryCacheConfig() {
    return {
      typePolicies: {
        UserType: {
          keyFields: ['email'],
        },
        CartType: {
          keyFields: (data, context) => {
            return `${context.typename}:${data.id}:${data.cartType}`
          },
          fields: {
            items: {
              merge(_, incomingItems) {
                return incomingItems
              },
            },
            bundles: {
              merge(_, incomingItems) {
                return incomingItems
              },
            },
            extras: {
              merge(_, incomingItems) {
                return incomingItems
              },
            },
            discounts: {
              merge(_, incomingItems) {
                return incomingItems
              },
            },
          },
        },
        ProductType: {
          keyFields: (data, context) => {
            return `${context.typename}:${data.id}:${data.bundleQuantity || 1}`
          },
        },
        // Set DiscountType data to not normalized by apollo
        // because it may confuse discount data from
        // availableDiscounts query or cart.discounts data
        // cart.discount should be accessed from cart parent data
        DiscountType: {
          keyFields: false,
        },
        Query: {
          fields: {
            ingredients: this._generateFieldPaginationHandler('ingredients'),
            quizResults: this._generateFieldPaginationHandler('quizResults'),
            orders: this._generateFieldPaginationHandler('orders'),
          },
        },
      },
    }
  }

  /**
   * createErrorLink
   *
   * @returns {ApolloLink}
   */
  createErrorLink() {
    return onError(({ operation, graphQLErrors, networkError }) => {
      setTimeout(
        () =>
          this._sendErrorToSentry(
            operation.operationName,
            operation.variables,
            graphQLErrors,
            networkError
          ),
        100
      )
    })
  }

  /**
   * createApolloFetchHandler
   *
   * @returns {Promise<Response>}
   */
  createApolloFetchHandler({
    getTokens,
    getUtmData,
    getAnalyticsData,
    getTiktokData,
    setAccessToken,
    setRefreshToken,
    setTokenTime,
    clear,
  }) {
    return async function apolloFetch(uri, options) {
      const { accessToken, refreshToken, tokenGenerateTime } = getTokens()
      let headerToken = accessToken

      if (refreshToken && tokenGenerateTime) {
        const tokenTimeAfterFiveMinutes = dayjs(
          decodeURIComponent(tokenGenerateTime)
        ).add(5, 'minutes')
        const timeNow = dayjs()

        if (timeNow.isSameOrAfter(tokenTimeAfterFiveMinutes)) {
          const additionalData = {
            ...getUtmData(),
            ...getAnalyticsData(),
            ...getTiktokData(),
          }

          const { data: { tokens } = {}, errors } = await fetch(uri, {
            headers: {
              ...options.headers,
              authorization: `Bearer ${accessToken}`,
              accept: '*/*',
              'content-type': 'application/json',
              'accept-encoding': 'gzip',
            },
            method: 'POST',
            body: JSON.stringify({
              query: `mutation refreshAccessTokenMutation(
                  $refreshToken: String!
                  $data: AdditionalLoginInfoInput
                ) {
                  tokens: refreshAccessToken(refreshToken: $refreshToken, data: $data) {
                    accessToken
                    refreshToken
                    tokenGenerateTime
                  }
                }`,
              variables: {
                refreshToken,
                data: additionalData,
              },
            }),
          }).then(response => response.json())

          if (tokens) {
            setAccessToken(tokens.accessToken)
            setRefreshToken(tokens.refreshToken)
            setTokenTime(tokens.tokenGenerateTime)

            headerToken = tokens.accessToken
          } else {
            const isUnauthorized = errors?.find(
              err => err.code === 'Unauthorized'
            )

            if (isUnauthorized && typeof window !== 'undefined') {
              clear()
              window.location.reload()
              return
            }

            throw new Error('Unauthorized')
          }
        }
      }

      return fetch(uri, {
        ...options,
        headers: {
          ...options.headers,
          authorization: `Bearer ${headerToken}`,
          accept: '*/*',
          'content-type': 'application/json',
          'accept-encoding': 'gzip',
        },
        // current NextJS is using node-fetch v2, so highWaterMark is not useful for now
        // highWaterMark: 10 * 1024 * 1024,
      })
    }
  }
}

export default ApolloUtility
