/**
 * cancelablePromise
 *
 * This is an utility function to create a cancelable promise
 *
 * @param {Promise} promise
 */
function cancelablePromise(promise) {
  let _canceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      result => (_canceled ? reject({ isCanceled: true }) : resolve(result)),
      error => (_canceled ? reject({ isCanceled: true }) : reject(error))
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      _canceled = true
    },
  }
}

export default cancelablePromise
