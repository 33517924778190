import { useState } from 'react'
import debounce from 'debounce'

import useDidMount from './useDidMount'

/**
 * useIsWidthLarger
 *
 * Helper hooks to get if device width
 * is larger than input
 *
 * @param {Number} width device width
 * @returns {Boolean}
 */
function useIsWidthLarger(width) {
  const [isLarger, setIsLarger] = useState(false)

  useDidMount(() => {
    const resizeListener = () => setIsLarger(global.window.innerWidth >= width)
    const debouncedListener = debounce(resizeListener, 100)
    if (global.window.innerWidth >= width) setIsLarger(true)

    global.window.addEventListener('resize', debouncedListener)

    return () => {
      global.window.removeEventListener('resize', debouncedListener)
    }
  })

  return isLarger
}

export default useIsWidthLarger
