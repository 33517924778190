import * as Sentry from '@sentry/core'

export default function initializeWorkbox(env) {
  if (
    (env !== 'local' || process.env.NEXT_PUBLIC_PWA === 'true') &&
    typeof window !== 'undefined' &&
    'serviceWorker' in navigator
  ) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => {
        registration
          .unregister()
          .then(isUnregistered => {
            if (isUnregistered) window.location.reload()
          })
          .catch(err => {
            Sentry.captureException(err, {
              contexts: { source: 'service-worker' },
            })
          })
      })
    })
  }
}
