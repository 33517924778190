import { createContext, useContext, useMemo } from 'react'
import dayjs from 'dayjs'

import appConfig from '@config/app'

function checkHasWindowAndTrackingID() {
  return !!(
    typeof global.window !== 'undefined' &&
    global.window.dataLayer &&
    appConfig.trackingMeasurementId
  )
}

function setUserData(userData) {
  if (!checkHasWindowAndTrackingID()) return

  const sanitizedPhoneNumber =
    userData && userData.phoneNumber
      ? userData.phoneNumber.replace(/\D/g, '')
      : undefined
  const dob = userData.birthday ? dayjs(userData.birthday) : undefined

  let userGender

  switch (userData.gender) {
    case 'Male':
      userGender = 'm'
      break
    case 'Female':
      userGender = 'f'
      break
  }

  global.window.dataLayer.push({
    event: 'config',
    user_id: userData.id,
    user_email: userData.email,
    user_phone: sanitizedPhoneNumber,
    user_gender: userGender,
    user_birthday: dob ? dob.format('YYYY-MM-DD') : undefined,
    user_age: dob ? dayjs().diff(dob, 'years') : undefined,
  })
}

function unsetUserData() {
  if (!checkHasWindowAndTrackingID()) return

  global.window.dataLayer.push(function () {
    this.reset()
  })
}

function pseudoPageView(path) {
  if (!checkHasWindowAndTrackingID()) return

  global.window.dataLayer.push({
    event: 'gtm.historyChange',
    page_path: `${path}${global.window.location.search}`,
  })
}

function trackEvent(eventName, eventData) {
  if (!checkHasWindowAndTrackingID()) return

  global.window.dataLayer.push({
    event: eventName,
    eventModel: { ...eventData },
  })
}

function trackVirtualPageView(path, pageTitle) {
  if (!checkHasWindowAndTrackingID()) return

  global.window.dataLayer.push({
    event: 'page_view',
    eventModel: {
      page_path: `/virtual${path}`,
      page_title: `Virtual - ${pageTitle}`,
    },
  })
}

export const useTracker = () => {
  const store = useMemo(() => {
    return {
      checkHasWindowAndTrackingID,
      setUserData,
      unsetUserData,
      pseudoPageView,
      trackEvent,
      trackVirtualPageView,
    }
  }, [])
  return store
}

const TrackerContext = createContext({
  checkHasWindowAndTrackingID,
  setUserData,
  unsetUserData,
  pseudoPageView,
  trackEvent,
  trackVirtualPageView,
})

export const TrackerProvider = ({ children, value }) => {
  return (
    <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>
  )
}

const useTrackerContext = () => {
  return useContext(TrackerContext)
}

export default useTrackerContext
