import { useEffect } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import { ApolloProvider } from '@apollo/client'
import nProgress from 'nprogress'

import ErrorBoundary from '@components/ErrorBoundary'
// import Navbar from '@components/Navbar'
import NewNavbar from '@components/NewNavBar'

import { LocalProvider, useLocal } from '@utils/localContext'
import { TrackerProvider, useTracker } from '@utils/trackerContext'
import { AuthProvider } from '@utils/auth/client'
import { setUser, unsetUser } from '@utils/sentry'
import useApollo from '@utils/apollo'
import { useScrollBehaviorModule } from '@utils/hooks'
import initWorkbox from '@utils/workbox'

import appConfig from '@config/app'
import { getCurrentUserData } from '@gql/userQuery'

import 'sanitize.css'
import '../vars.css'
import '../globals.css'
import '../nprogress.css'

nProgress.configure({
  minimum: 0.2,
  trickleSpeed: 300,
})

import nexti18nConfig from '../../next-i18next.config'

function CustomApp({ Component, pageProps }) {
  useScrollBehaviorModule()

  const client = useApollo(pageProps)
  const router = useRouter()
  const local = useLocal()
  const tracker = useTracker()

  const getLayout = Component.getLayout || (page => page)

  useEffect(() => {
    // Router isReady is used because in SSG,
    // component will be mounted twice
    // this happen from next router.query updated
    // if there are query params on url path

    if (router.isReady) initWorkbox(appConfig.environment)
  }, [router.isReady])

  useEffect(() => {
    if (
      router.isReady &&
      router.query?.utm_source &&
      router.query?.utm_medium
    ) {
      let utmSource = router.query?.utm_source
      if (
        Array.isArray(router.query?.utm_source) &&
        router.query?.utm_source.length > 0
      ) {
        utmSource = router.query?.utm_source.join(',')
      }

      let utmMedium = router.query?.utm_medium
      if (
        Array.isArray(router.query?.utm_medium) &&
        router.query?.utm_medium.length > 0
      ) {
        utmMedium = router.query?.utm_medium.join(',')
      }

      let utmCampaign = router.query?.utm_campaign
      if (
        Array.isArray(router.query?.utm_campaign) &&
        router.query?.utm_campaign.length > 0
      ) {
        utmCampaign = router.query?.utm_campaign.join(',')
      }

      local.setUtmData({
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
      })
    }

    if (router.isReady && router.query?.gclid) {
      local.setGclid(router.query?.gclid)
    }

    if (router.isReady && router.query?.ttclid) {
      const [urlPath, searchParam] = router.asPath.split('?')
      const urlSearchParam = new URLSearchParams(searchParam)
      urlSearchParam.delete('ttclid')

      const paramString = urlSearchParam.toString()
      router.replace(
        `${urlPath}${paramString !== '' ? '?' + paramString : ''}`,
        urlPath,
        {
          shallow: true,
        }
      )
    }
  }, [router, local])

  useEffect(() => {
    function handleRouteStart() {
      nProgress.start()
    }
    function handleRouteChange() {
      nProgress.done()
    }

    router.events.on('routeChangeStart', handleRouteStart)
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteStart)
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, tracker])

  useEffect(() => {
    async function loadUser() {
      try {
        const { data: { currentUser } = {} } = await client.query({
          query: getCurrentUserData,
          errorPolicy: 'ignore',
        })

        if (currentUser) {
          setUser(currentUser)
          local.setQiscusWidgetUser(
            currentUser.email,
            currentUser.name,
            currentUser.id
          )
          tracker.setUserData(currentUser)
        } else {
          unsetUser()
          tracker.unsetUserData()
        }
      } catch (err) {
        //
      }
    }

    if (router.isReady) loadUser()
  }, [local, tracker, client, router.isReady])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=10"
        />
      </Head>

      {appConfig.trackingMeasurementId && (
        <>
          {/* Anti Flicker Snippet */}
          <Script
            id="anti-flicker"
            dangerouslySetInnerHTML={{
              __html: `
                  (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
                  h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
                  (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;})
                  (window,document.documentElement,'async-hide','dataLayer',1500,{'${appConfig.trackingMeasurementId}':true})
                `,
            }}
          />

          {/* Google Tag Manager */}
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${appConfig.trackingMeasurementId}');
            `}
          </Script>

          {/* Google Tag Manager noscript */}
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
                <iframe
                  src='https://www.googletagmanager.com/ns.html?id=${appConfig.trackingMeasurementId}'
                  height="0"
                  width="0"
                  style="display:none;visibility:hidden"
                >
                </iframe>
              `,
            }}
          />
        </>
      )}

      <ErrorBoundary>
        <AuthProvider apolloClient={client} pageProps={pageProps}>
          <ApolloProvider client={client}>
            <LocalProvider value={local}>
              <TrackerProvider value={tracker}>
                <NewNavbar />
                {getLayout(<Component {...pageProps} />)}
              </TrackerProvider>
            </LocalProvider>
          </ApolloProvider>
        </AuthProvider>
      </ErrorBoundary>

      <Script
        id="embed-ig"
        src="https://www.instagram.com/embed.js"
        strategy="lazyOnload"
      />
      <Script
        id="embed-tiktok"
        src="https://www.tiktok.com/embed.js"
        strategy="lazyOnload"
      />
    </>
  )
}

export default appWithTranslation(CustomApp, nexti18nConfig)
