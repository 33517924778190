import gql from 'graphql-tag'

import { Discount } from './fragments'

export const getAvailableDiscounts = gql`
  query getAvailableDiscounts($userId: String) {
    availableDiscounts(userId: $userId) {
      ...Discount
      productIds
      subcategoryIds
    }
  }
  ${Discount}
`
