import gql from 'graphql-tag'

import {
  Address,
  Card,
  UserIdentity,
  Voucher,
  Discount,
  UserBFFData,
  UserPointData,
} from './fragments'

export const authenticateUser = gql`
  query authenticateUser {
    user: authenticateUser {
      id
      email
      phoneNumber
      name
      isBFF
      isPhoneVerified
    }
  }
`

export const isEmailRegisteredQuery = gql`
  query isEmailRegistered($email: String!) {
    isEmailRegistered(email: $email)
  }
`

export const getCurrentUserData = gql`
  query getCurrentUserData {
    currentUser {
      ...UserIdentity
      phoneNumber
      gender
      birthday
      baseFriendUserData {
        id
      }
    }
  }
  ${UserIdentity}
`

export const getCurrentUserBFFData = gql`
  query getCurrentUserBFFData {
    currentUser {
      ...UserIdentity
      phoneNumber
      gender
      birthday

      baseFriendUserData {
        ...UserBFFData
      }

      points {
        ...UserPointData
      }
    }
  }
  ${UserIdentity}
  ${UserBFFData}
  ${UserPointData}
`

export const getCurrentUserDataWithAddress = gql`
  query getCurrentUserDataWithAddress {
    currentUser {
      ...UserIdentity
      phoneNumber
      gender
      birthday

      addresses {
        ...Address
      }
    }
  }
  ${UserIdentity}
  ${Address}
`

export const getCurrentUserDataWithCards = gql`
  query getCurrentUserDataWithCards {
    currentUser {
      ...UserIdentity
      phoneNumber
      gender
      birthday

      cards {
        ...Card
      }
    }
  }
  ${UserIdentity}
  ${Card}
`

export const getCurrentUserForNavbar = gql`
  query getCurrentUserForNavbar {
    currentUser {
      ...UserIdentity
      phoneNumber
      gender
      birthday
      hasCompletedOrder

      firstVoucher {
        ...Voucher
        discount {
          ...Discount
        }
      }
    }
  }
  ${UserIdentity}
  ${Voucher}
  ${Discount}
`

export const getCurrentUserDataForPayment = gql`
  query getCurrentUserDataForPayment {
    currentUser {
      ...UserIdentity
      phoneNumber
      gender
      birthday

      addresses {
        ...Address
      }
      cards {
        ...Card
      }
    }
  }
  ${UserIdentity}
  ${Address}
  ${Card}
`

export const getCurrentUserAllData = gql`
  query getCurrentUserAllData {
    currentUser {
      ...UserIdentity
      phoneNumber
      gender
      birthday
      hasCompletedOrder

      addresses {
        ...Address
      }
      firstVoucher {
        ...Voucher
        discount {
          ...Discount
        }
      }
      baseFriendUserData {
        ...UserBFFData
      }
      points {
        ...UserPointData
      }
    }
  }
  ${Address}
  ${UserIdentity}
  ${Voucher}
  ${Discount}
  ${UserBFFData}
  ${UserPointData}
`

export const getCurrentUserHasCompletedOrder = gql`
  query getCurrentUserHasCompletedOrder {
    currentUser {
      ...UserIdentity
      hasCompletedOrder
    }
  }
  ${UserIdentity}
`

export const getUserNotification = gql`
  query getUserNotification {
    notifications: userNotification {
      label
      count
    }
  }
`

export const verifyResetPasswordToken = gql`
  query verifyResetPasswordToken($token: String!) {
    verifyResetPasswordToken(token: $token)
  }
`

export const sendOTPToUser = gql`
  query sendOTPNumber {
    sendPhoneNumberOTPCode
  }
`
