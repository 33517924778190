import { createContext, useContext, useMemo } from 'react'
import { setCookie, getCookies, deleteCookie } from 'cookies-next'

import cookieConfig from '@config/cookie'
const { cookieKeys } = cookieConfig

/**
 * --- COOKIE ---
 */

const getCookieConfig = type => {
  const configObj = {
    path: '/',
    httpOnly: false,
  }

  switch (type) {
    case 'utm':
      configObj.maxAge = cookieConfig.utmExpires
      break
    case 'lang':
      configObj.maxAge = cookieConfig.langExpires
      break
    case 'auth':
      configObj.sameSite = 'strict'

      configObj.maxAge = cookieConfig.expires
      break
    default:
      configObj.maxAge = cookieConfig.expires
      break
  }

  return configObj
}

const getTokens = ctx => {
  const cookies = getCookies({
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig('auth'),
  })

  return {
    accessToken: cookies[cookieKeys.accessToken],
    refreshToken: cookies[cookieKeys.refreshToken],
    tokenGenerateTime: cookies[cookieKeys.tokenGenerateTime],
  }
}

const setAccessToken = (ctx, value) =>
  setCookie(cookieKeys.accessToken, value, {
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig('auth'),
  })
const setRefreshToken = (ctx, value) =>
  setCookie(cookieKeys.refreshToken, value, {
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig('auth'),
  })
const setTokenTime = (ctx, value) =>
  setCookie(cookieKeys.tokenGenerateTime, value, {
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig('auth'),
  })

const getUTMData = ctx => {
  const cookies = getCookies({ req: ctx?.req, res: ctx?.res })

  return {
    utmSource: cookies[cookieKeys.utmSource],
    utmMedium: cookies[cookieKeys.utmMedium],
    utmCampaign: cookies[cookieKeys.utmCampaign],
  }
}

const setUTMData = (ctx, utmData) => {
  if (utmData.utm_source) {
    setCookie(cookieKeys.utmSource, utmData.utm_source, {
      req: ctx?.req,
      res: ctx?.res,
      ...getCookieConfig('utm'),
    })
  }
  if (utmData.utm_medium) {
    setCookie(cookieKeys.utmMedium, utmData.utm_medium, {
      req: ctx?.req,
      res: ctx?.res,
      ...getCookieConfig('utm'),
    })
  }
  if (utmData.utm_campaign) {
    setCookie(cookieKeys.utmCampaign, utmData.utm_campaign, {
      req: ctx?.req,
      res: ctx?.res,
      ...getCookieConfig('utm'),
    })
  }
}

const setGclid = (ctx, gclid) =>
  setCookie(cookieKeys.gclid, gclid, {
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig('utm'),
  })

const getOptimizeData = ctx => {
  const cookies = getCookies({ req: ctx?.req, res: ctx?.res })

  return {
    expId: cookies[cookieKeys.optimizeExpId],
    expVar: cookies[cookieKeys.optimizeExpVar],
  }
}

const setOptimizeExpId = (ctx, value) =>
  setCookie(cookieKeys.optimizeExpId, value, {
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig(),
  })
const setOptimizeExpVar = (ctx, value) =>
  setCookie(cookieKeys.optimizeExpVar, value, {
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig(),
  })

const getAnalyticsData = ctx => {
  const cookies = getCookies({ req: ctx?.req, res: ctx?.res })

  return {
    fbp: cookies[cookieKeys.fbp],
    fbc: cookies[cookieKeys.fbc],
    gaCid: cookies[cookieKeys.ga],
    gclid: cookies[cookieKeys.gclid],
  }
}

const getTiktokData = ctx => {
  const cookies = getCookies({
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig('utm'),
  })

  return {
    ttp: cookies[cookieKeys.ttp],
    ttclid: cookies[cookieKeys.ttclid],
  }
}

const getLang = ctx =>
  getCookies({ req: ctx?.req, res: ctx?.res })[cookieKeys.language]
const setLang = (ctx, value) =>
  setCookie(cookieKeys.language, value, {
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig('lang'),
  })

/**
 * --- LOCAL STORAGE ---
 */

const localStorageKeys = {
  qiscusWidget: 'qismo-widget',
  auth: '__AUTH__',
}

export const isLocalStorageAvailable = () => {
  try {
    localStorage.setItem('test', 'test')
    localStorage.removeItem('test')

    return true
  } catch (e) {
    return false
  }
}

const readLocalStorage = key => {
  if (!isLocalStorageAvailable()) return
  return localStorage.getItem(key)
}

const writeLocalStorage = (key, value) => {
  if (!isLocalStorageAvailable()) return
  return localStorage.setItem(key, value)
}

const removeLocalStorage = key => {
  if (!isLocalStorageAvailable()) return
  return localStorage.removeItem(key)
}

const getQiscusWidgetUser = () => {
  const widgetData = readLocalStorage(localStorageKeys.qiscusWidget)
  return widgetData && JSON.parse(widgetData)
}

const setQiscusWidgetUser = (email, name, userId) => {
  const widgetData = getQiscusWidgetUser()
  return writeLocalStorage(
    localStorageKeys.qiscusWidget,
    JSON.stringify({
      ...(widgetData || {}),
      unique_id: email,
      display_name: name,
      user_id: userId,
    })
  )
}

const clear = ctx => {
  deleteCookie(cookieKeys.accessToken, {
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig('auth'),
  })
  deleteCookie(cookieKeys.refreshToken, {
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig('auth'),
  })
  deleteCookie(cookieKeys.tokenGenerateTime, {
    req: ctx?.req,
    res: ctx?.res,
    ...getCookieConfig('auth'),
  })

  removeLocalStorage(localStorageKeys.qiscusWidget)
  removeLocalStorage(localStorageKeys.auth)
}

export const getLocalMethods = ctx => {
  return {
    getTokens: () => getTokens(ctx),
    setAccessToken: accessToken => setAccessToken(ctx, accessToken),
    setRefreshToken: refreshToken => setRefreshToken(ctx, refreshToken),
    setTokenTime: time => setTokenTime(ctx, time),
    getUtmData: () => getUTMData(ctx),
    setUtmData: utmData => setUTMData(ctx, utmData),
    setGclid: gclid => setGclid(ctx, gclid),
    getOptimizeData: () => getOptimizeData(ctx),
    setOptimizeExpId: expId => setOptimizeExpId(ctx, expId),
    setOptimizeExpVar: expVar => setOptimizeExpVar(ctx, expVar),
    getAnalyticsData: () => getAnalyticsData(ctx),
    getTiktokData: () => getTiktokData(ctx),
    setLang: lang => setLang(ctx, lang),
    getLang: () => getLang(ctx),
    getQiscusWidgetUser,
    setQiscusWidgetUser,
    clear: () => clear(ctx),
  }
}

export const useLocal = ctx => {
  const store = useMemo(() => getLocalMethods(ctx), [ctx])
  return store
}

/**
 * --- CONTEXT ---
 */

const LocalContext = createContext(getLocalMethods())

export const LocalProvider = ({ children, value }) => {
  return <LocalContext.Provider value={value}>{children}</LocalContext.Provider>
}

const useLocalContext = () => {
  return useContext(LocalContext)
}

export default useLocalContext
